<template src="./EventType.pug" lang="pug"></template>
<script>

import router from '@/router'

import * as types from '@/store/mutation-types'
import { store } from '@/store'

  export default {
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
    }),
    methods: {
      setEventType(type){
        switch (type) {
          case 'cha-de-bebe':
            store.commit(types.EVENT_TYPE, 'Chá de Bebê')
            break
          case 'cha-revelacao':
            store.commit(types.EVENT_TYPE, 'Chá Revelação')
            break
          case 'cha-rifa':
            store.commit(types.EVENT_TYPE, 'Chá Rifa')
            break
          default: break;
        }

        router.push({ name: 'themes' })
      },

      goTo(address){
        let href = ''
        switch (address)  {
          case 'instagram': href = 'https://www.instagram.com/ifraldas'
            break
          case 'facebook': href = 'https://www.facebook.com/ifraldas'
            break
          default: href = 'https://www.ifraldas.com.br?utm_source=lp+convites&utm_medium=landing+page&utm_campaign=convites&utm_id=teste&utm_term=ifraldas'
            break
        }
        window.open(href, '_blank');
      }

    }
  }
</script>
