<template src="./GenerateInvitation.pug" lang="pug"></template>
<script>

  import { mapGetters, mapActions } from 'vuex'
  import { saveAs } from 'file-saver'

  export default {
    name: 'GenerateInvitation',
    data() {
      return{
        imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
        url: ''
      }
    },
    computed: {
      ...mapGetters(['invitationUrl', 'base64', 'slug'])
    },
    methods: {
      ...mapActions(['downloadImg']),
      goToSite(){
        let href = 'https://www.ifraldas.com.br?utm_source=lp+convites&utm_medium=landing+page&utm_campaign=convites&utm_id=teste&utm_term=ifraldas'
        window.open(href, '_blank');
      },

      share(){
        window.dataLayer.push({ event: 'share_whatsapp' })
        let href = 'https://api.whatsapp.com/send?text=Ol%C3%A1%21%20Esperamos%20voc%C3%AA%20na%20nossa%20festa%21%20https://cha.ifraldas.com.br/convite/'+this.slug // adicionar slug
        window.open(href, '_blank');
      },

      toDataURL(url) {
        return fetch(url).then((response) => {
          return response.blob();
        }).then(blob => {
        return URL.createObjectURL(blob);
        })
      },

      async download() {
        await this.downloadImg({ url: this.invitationUrl.split("cdn.ifraldas.net/")[1] })
        const base64Response = await fetch(`data:image/png;base64,${this.base64}`)
        const blob = await base64Response.blob();
        saveAs(blob, `iFraldas.png`)
      },

      goTo(address){
        let href = ''
        switch (address)  {
          case 'instagram': href = 'https://www.instagram.com/ifraldas'
            break
          case 'facebook': href = 'https://www.facebook.com/ifraldas'
            break
          default: href = 'https://www.ifraldas.com.br?utm_source=lp+convites&utm_medium=landing+page&utm_campaign=convites&utm_id=teste&utm_term=ifraldas'
            break
        }
        window.open(href, '_blank');
      },

    },
    mounted() {

      // if(this.invitationUrl == null){
      //   router.push({ name: 'landing' })
      // }
    }
  }
</script>
<style>
  .event-share-whatsapp {
    /* classe para google tag manager */
  }
</style>
